<template>
<section class="about_m">
   <img class="banner" :src="$t('locale')=='zh'?banner:banner_en" alt="" />
   <ul>
      <li class="frist_li">
         <mu-container>
            <mu-tabs
               :value.sync="active1"
               inverse
               full-width>
               <mu-tab>{{ $t("PC.About.byrxfs") }}</mu-tab>
               <mu-tab>{{ $t("PC.About.6wik3r") }}</mu-tab>
            </mu-tabs>
            <div class="tab_1 tab" v-if="active1 === 0">
               <img src="../../assets/zh/images-mobile/aboutus_img1@2x.png" alt="" />
               <p>
                  {{ $t("PC.About.vljjpg") }}
               </p>
            </div>
            <div class="tab_2 tab" v-if="active1 === 1">
               <div class="box">
                  <p class="p1">{{ $t("PC.About.j10i6n") }}</p>
                  <span class="p2" v-html="$t('PC.About.bq0ufd')"> </span>
               </div>
               <div class="box">
                  <p class="p1">{{ $t("PC.About.6pjvyv") }}</p>
                  <p class="p2">
                     {{ $t("PC.About.bqgqjp") }}
                  </p>
               </div>
               <div class="box">
                  <p class="p1">{{ $t("PC.About.6x1gqh") }}</p>
                  <p class="p2">
                     {{ $t("PC.About.h9xq6c") }}
                  </p>
               </div>
            </div>
            <div class="tab_3 tab" v-if="active1 === 2">
               <h1>{{ $t("PC.About.5cjk9v") }}</h1>
               <p>{{ $t("PC.About.ia35og") }}</p>
               <span>{{ $t("PC.About.bbrgxc") }}</span>
               <p>{{ $t("PC.About.tpb6xv") }}</p>
               <span>{{ $t("PC.About.owm9kp") }}</span>
               <p>{{ $t("PC.About.dj6fzo") }}</p>
               <span class="last_span">{{ $t("PC.About.3obs4n") }}</span>
            </div>
            <div class="tab_4 tab" v-if="active1 === 3">
               <div>
                  <img src="../../assets/zh/images-mobile/aboutus_img3@2x.png" alt="" />
                  <figure>
                     <figcaption>
                        <span>{{ $t("PC.About.qhn51h") }}</span>
                        <i>/ {{ $t("PC.About.h4j7mr") }}</i>
                     </figcaption>
                     <h1>{{ $t("PC.About.obcdde") }}</h1>
                     <h1>{{ $t("PC.About.89wyrt") }}</h1>
                     <h1>{{ $t("PC.About.g8lvfo") }}</h1>
                     <h1>{{ $t("PC.About.uoass2") }}</h1>
                  </figure>
               </div>
               <div>
                  <img src="../../assets/zh/images-mobile/aboutus_img4@2x.png" alt="" />
                  <figure>
                     <figcaption>
                        <span>{{ $t("PC.About.2ovdhp") }}</span>
                        <i>/ {{ $t("PC.About.tnzyhc") }}</i>
                     </figcaption>
                     <h1>{{ $t("PC.About.3vtkqg") }}</h1>
                     <h1>{{ $t("PC.About.3hmkyf") }}</h1>
                     <h1>{{ $t("PC.About.lepnph") }}</h1>
                     <h1>{{ $t("PC.About.238szb") }}</h1>
                     <h1>{{ $t("PC.About.auc9cv") }}</h1>
                     <h1>（IEEE FELLOW）</h1>
                  </figure>
               </div>
            </div>
         </mu-container>
      </li>
      <li class="licheng">
         <p>{{ $t("PC.About.cdtm6x") }}</p>
         <div class="box_contain" :class="$i18n.locale == 'en' ? 'morethen_height' : 'normal_height'">
            <div class="center_b"></div>
            <div class="right">
               <div class="box box_2">
                  <p>{{ $t("PC.About.wre2me") }}</p>
                  <span>{{ $t("PC.About.v9fqry") }}</span>
                  <div class="article_line">
                     <div class="sliver"></div>
                     <div class="circle c_outside">
                        <div class="circle c_inside"></div>
                     </div>
                  </div>
               </div>
               <div class="box box_4">
                  <p>{{ $t("PC.About.ldgmk8") }}</p>
                  <span>{{ $t("PC.About.hg71ro") }}</span>
                  <div class="article_line">
                     <div class="sliver"></div>
                     <div class="circle c_outside">
                        <div class="circle c_inside"></div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="left">
               <div class="box box_1">
                  <p>{{ $t("PC.About.1dhuqs") }}</p>
                  <span>{{ $t("PC.About.14qiag") }}</span>
                  <div class="article_line">
                     <div class="sliver"></div>
                     <div class="circle c_outside">
                        <div class="circle c_inside"></div>
                     </div>
                  </div>
               </div>
               <div class="box box_3">
                  <p>{{ $t("PC.About.1otmo7") }}</p>
                  <span>{{ $t("PC.About.vhmzyl") }}</span>
                  <div class="article_line">
                     <div class="sliver"></div>
                     <div class="circle c_outside">
                        <div class="circle c_inside"></div>
                     </div>
                  </div>
               </div>
               <div class="box box_5">
                  <p>{{ $t("PC.About.lhwvrp") }}</p>
                  <span>{{ $t("PC.About.qfj6p8") }}</span>
                  <div class="article_line">
                     <div class="sliver"></div>
                     <div class="circle c_outside">
                        <div class="circle c_inside"></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </li>
      <li class="last_li">
         <div class="map_contain">
            <h1>{{ $t("PC.Service.txdblr") }}</h1>
            <img src="../../assets/zh/images-mobile/aboutus_img2@2x.png" alt="" />
            <div>
               <p>{{ $t("PC.Service.bqpct5") }}</p>
               <p>{{ $t("PC.Service.xz6nnu") }}</p>
               <p>{{ $t("PC.Service.0ldt5w") }}</p>
            </div>
         </div>
      </li>
   </ul>
   <FooterB></FooterB>
</section>
</template>

<script>
import FooterB from "./components/FooterB";
export default {
   components: {
      FooterB
   },
   data() {
      return {
         active1: 0,
         banner: require('../../assets/zh/images-mobile/aboutus_banner@2x.png'),
         banner_en: require('../../assets/en/images-mobile/aboutus_banner@2x.png'),
      };
   }
};
</script>

<style lang="scss" scoped>
.about_m {
   margin-top: 48px;
   word-break: break-word !important;

   img.banner {
      width: 100%;
      height: 150px;
   }

   ul {
      li.frist_li {
         div.container {
            padding: 0;

            .mu-tabs {
               z-index: 3;
            }

            /deep/.mu-tabs-inverse {
               background-color: #fff;
               color: #333333;
            }

            /deep/.tab {
               background: #f5f6f9;
            }

            /deep/.mu-secondary-text-color {
               color: #003299;
            }

            /deep/.mu-tab-link-highlight {
               background-color: transparent !important;
            }

            /deep/.mu-tab-active.is-inverse {
               // color: rgba(0, 0, 0, .87);
               color: #003299;
            }

            /deep/.mu-tab-wrapper {
               font-weight: 500 !important;
               padding: 0;
            }

            .tab {
               box-sizing: border-box;
               padding: 20px;
            }

            .tab_1 {
               img {
                  width: 174px;
                  height: 128px;
                  margin: 0 auto;
                  padding-bottom: 8px;
               }

               p {
                  font-size: 12px;
                  font-family:   PingFangSC-Regular;
                  color: #666;
                  line-height: 21px;
               }
            }

            .tab_2 {
               .box {
                  width: 243px;
                  margin: 0 auto;
                  box-sizing: border-box;
                  padding: 20px;
                  background-image: url("../../assets/zh/images-mobile/aboutus_beijing1@2x.png");
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: 100% 100%;
                  margin-bottom: 16px;

                  .p1 {
                     font-size: 14px;
                     color: #333;
                     line-height: 20px;
                     font-weight: 600;
                     text-align: center;
                     padding-bottom: 10px;
                  }

                  .p2 {
                     font-size: 12px;
                     font-family:   PingFangSC-Regular, PingFang SC;
                     color: #666;
                     line-height: 21px;

                     i {
                        white-space: nowrap;
                        font-family:   PingFangSC-Regular, PingFang SC;
                     }
                  }
               }
            }

            .tab_3 {
               padding: 20px 43px;

               h1 {
                  font-size: 16px;
                  font-weight: 600;
                  color: #333;
                  line-height: 22px;
                  padding-bottom: 10px;
                  border-bottom: 2px solid #e8ecf4;
                  margin-bottom: 17px;
               }

               p {
                  font-size: 14px;
                  font-weight: 500;
                  color: #003299;
                  line-height: 20px;
                  padding-bottom: 4px;
               }

               span {
                  font-size: 12px;
                  font-family:   PingFangSC-Regular, PingFang SC;
                  color: #666;
                  line-height: 21px;
                  padding-bottom: 16px;
               }

               .last_span {
                  padding: 0;
               }
            }

            .tab_4 {
               div {
                  box-sizing: border-box;
                  padding: 20px 0;
                  width: 243px;
                  margin: 0 auto;
                  background-color: #eaeff8;
                  margin-bottom: 16px;

                  img {
                     width: 88px;
                     height: 88px;
                     margin: 0 auto;
                  }

                  figure {
                     text-align: center;

                     figcaption {
                        text-align: center;
                        padding: 24px 0 12px;

                        span {
                           font-size: 14px;
                           font-weight: 600;
                           color: #003299;
                           line-height: 20px;
                        }

                        i {
                           display: inline-block;
                           font-size: 10px;
                           font-family:   PingFangSC-Regular, PingFang SC;
                           color: #333;
                        }
                     }

                     h1 {
                        font-size: 12px;
                        font-family:   PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 21px;
                     }
                  }
               }
            }
         }
      }

      li.licheng {
         background-color: #fff;
         box-sizing: border-box;
         padding: 20px;

         &>p {
            font-size: 16px;
            font-weight: 600;
            color: #333;
            line-height: 22px;
            text-align: center;
            padding-bottom: 16px;
         }

         .box_contain {
            position: relative;

            .center_b {
               position: absolute;
               top: 55px;
               left: calc(50% - 1px);
               width: 2px;
               height: calc(100% - 65px);
               background: #e8ecf4;
            }

            .box {
               position: relative;
               width: 135px;
               height: auto;
               background: #f5f9fc;
               border-radius: 2px;
               box-sizing: border-box;
               padding: 6px 14px 11px 11px;

               p {
                  font-size: 14px;
                  font-weight: 600;
                  color: #003299;
                  line-height: 24px;
               }

               span {
                  font-family:   PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;
                  line-height: 15px;
                  -webkit-text-size-adjust: none;
                  font-size: 10px;
               }

               div.article_line {
                  position: absolute;
                  display: flex;
                  align-items: center;
                  transform-origin: center center;
                  top: calc(50% - 4px);

                  .sliver {
                     width: 28px;
                     height: 2px;
                     background-color: #003299;
                  }

                  .circle {
                     width: 8px;
                     height: 8px;
                     background-color: #003299;
                     border-radius: 50%;
                     position: relative;
                     z-index: 0;
                     display: flex;
                     justify-content: center;
                     align-items: center;

                     .c_inside {
                        width: 4px;
                        height: 4px;
                        background: #ffffff;
                     }
                  }
               }
            }

            .right {
               width: 135px;
               height: 100px;
               position: absolute;
               top: 0;
               right: 0;

               .box_2 {
                  margin-top: 63px;
               }

               .box_4 {
                  margin-top: 33px;
               }

               div.article_line {
                  transform: rotate(180deg);
                  right: 100%;
               }
            }

            .left {
               width: 135px;
               height: 1000px;
               position: absolute;
               top: 0;
               left: 0;

               .box {
                  margin-bottom: 16px;
               }

               .box_5 {
                  margin-bottom: 0px;
               }

               div.article_line {
                  left: 100%;
               }
            }
         }

         .morethen_height {
            height: 590px;
         }

         .normal_height {
            height: 346px;
         }
      }

      li.last_li {
         box-sizing: border-box;
         padding: 20px;
         padding-bottom: 32px;

         .map_contain {
            width: 335px;
            margin: 0 auto 32px;

            h1 {
               font-size: 16px;
               font-weight: 600;
               color: #333;
               line-height: 22px;
               text-align: center;
               padding-bottom: 16px;
            }

            img {
               width: 335px;
               height: 190px;
            }

            div {
               width: 335px;
               height: auto;
               background: #282a35;
               box-sizing: border-box;
               padding: 48px;

               p {
                  color: #fff;
                  height: auto;
                  font-size: 12px;
                  line-height: 17px;
                  margin-bottom: 16px;
               }
            }
         }
      }
   }
}
</style>
